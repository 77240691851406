<template>
  <!-- 司机管理 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('driver_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>

    <car-detail
      v-model="DriverModel"
      :title="DriverTitle"
      :DefluteValue="DefluteValue"
      :defaultEdeit="defaultEdeit"
      @refrestList="refrestList"
    ></car-detail>
    <pic-view v-model="checkPic" :src="imgSrc"></pic-view>
  </div>
</template>

<script>
import moment from "moment";
import carDetail from "./driverModel";
import picView from "@/components/picture_view";
export default {
  name: "",
  components: {
    carDetail,
    picView,
  },
  data() {
    return {
      DriverModel: false,
      DriverTitle: "",
      DefluteValue: {},
      table: {
        columns: [
          {
            title: "图片",
            width: 100,
            align: "center",
            render: (h, { row }) => {
              if(row.img!==''){
                return (
                <div
                  style="width:60px;height:60px;padding:4px;cursor:pointer"
                  on-click={() => this.checkPicInfo(row)}
                >
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(row.servicePath, row.img)}
                  />
                </div>
              );
              }
              return (
                <span>--</span>
              );
            },
          },
          {
            title: "姓名",
            align: "center",
            key: "name",
          },
          {
            title: "性别",
            width: 70,
            align: "center",
            render: (h, { row }) => {
              return <div>{row.sex == "1" ? "男" : "女"}</div>;
            },
          },
          {
            title: "驾驶证编号",
            align: "center",
            key: "driverNo",
          },
          {
            title: "从业资格有效期",
            align: "center",
            render: (h, { row }) => {
              return (
                <span>{moment(row.noValidPeriod).format("YYYY-MM-DD")}</span>
              );
            },
            width: 200,
          },

          {
            title: "工作证",
            align: "center",
            key: "wordNo",
          },
          {
            title: "出生日期",
            align: "center",
            render: (h, { row }) => {
              return <span>{moment(row.birthday).format("YYYY-MM-DD")}</span>;
            },
          },
          {
            title: "电话",
            width: 130,
            key: "phone",
          },
          {
            title: "邮箱",
            width: 160,
            key: "email",
          },
          {
            title: "地址",
            minWidth: 180,
            key: "address",
          },
          {
            title: "操作",
            width: 220,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("driver_stop") && row.status == 1 && (
                    <Poptip
                      confirm
                      transfer
                      title="确定停用吗?"
                      on-on-ok={() => this.stop(row.id)}
                    >
                      <a style="margin-right: 10px">停用</a>
                    </Poptip>
                  )}
                  {this.CA("driver_open") && row.status == 2 && (
                    <Poptip
                      confirm
                      transfer
                      title="确定启用吗?"
                      on-on-ok={() => this.open(row.id)}
                    >
                      <a style="margin-right: 10px">启用</a>
                    </Poptip>
                  )}
                  {this.CA("driver_update") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("driver_check") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      司机详情
                    </a>
                  )}
                  {this.CA("driver_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增司机",
            ca: "driver_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "姓名",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "驾驶证编号",
              component: "input",
              field: "driverNo",
              defaultValue: "",
            },
            {
              conditionName: "工作证",
              component: "input",
              field: "wordNo",
              defaultValue: "",
            },
            
            // {
            //   conditionName: "地址",
            //   component: "input",
            //   field: "address",
            //   defaultValue: "",
            // },
             {
              conditionName: "电话",
              component: "input",
              field: "phone",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      defaultEdeit: false,
      deptList: [],
      search_data: {},
      imgSrc: "",
      checkPic: false,
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.DriverModel = true;
      this.DriverTitle = "添加司机";
      this.defaultEdeit = false;
      this.DefluteValue = {};
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    refrestList() {
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },
    getList() {
      if (!this.CA("driver_check")) return;
      this.table.loading = true;
      this.$post(this.$api.DRIVER_MANAGE.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          // console.log("====res.list", res.list);
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //查看图片
    checkPicInfo(row) {
      this.checkPic = true;
      this.imgSrc = row.servicePath + row.img;
    },
    stop(id) {
      this.$post(this.$api.DRIVER_MANAGE.STOP, {
        id
      }).then(() => {
        this.$Message.success('停用成功')
        this.getList()
      })
    },
    open(id) {
      this.$post(this.$api.DRIVER_MANAGE.OPEN, {
        id
      }).then(() => {
        this.$Message.success('启用成功')
        this.getList()
      })
    },
    edit(row) {
      this.defaultEdeit = false;
      this.DriverModel = true;
      this.DriverTitle = "修改司机";
      this.DefluteValue = row;
    },
    checkInfo(row) {
      this.defaultEdeit = true;
      this.DefluteValue = row;
      this.DriverModel = true;
      this.DriverTitle = "司机详情";
    },

    delete(id) {
      this.$post(this.$api.DRIVER_MANAGE.DELETE, {
        userId: id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    //获取部门列表
    getDeptList() {
      this.$post(this.$api.company.BARNCHTREE)
        .then((res) => {
          this.deptList = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        driverNo: "",
        companyNo: "",
        noValidPeriod: "",
        phone: "",
        email: "",
        birthday: "",
        address: "",
        birthday: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getDeptList();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>